.dot-wrapper {
  width: 16px;
  height: 16px;
  border: 1px solid #697ea4;
  border-radius: 50%;
  margin-top: 30px;
}

.dot-custom {
  background-color: transparent;
  width: 8px;
  height: 8px;
  border-radius: 50%;
}

.slick-active > .dot-wrapper .dot-custom {
  background-color: #697ea4 !important;
}
