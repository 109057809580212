.call-to-action {
  background-color: #303a4d !important;
  border-color: #303a4d !important;
  border-radius: 50px !important;
  width: 160px;
  height: 40px;
  box-shadow: unset !important;
}

.nav-link {
  color: #f0f4fa !important;
}

.navbar-brand img {
  width: 200px;
  height: auto;
}

@media (min-width: 350px) {
  .navbar-brand img {
    width: auto !important;
  }
}
