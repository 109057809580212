.app-pres-title {
  text-align: left;
  font-family: 'Source Sans Pro';
  font-size: 30px;
  font-weight: 500;
  letter-spacing: 0px;
  color: #303a4d;
  
}


