.footer-topbar {
  background-color: #5c6e90;
  padding: 25px;
}

.footer-topbar a,
.footer-topbar span {
  font: normal normal 600 20px/28px Source Sans Pro;
  letter-spacing: 0px;
  color: #ffffff;
  text-decoration: unset;
}

.footer-topbar a:hover {
  color: #ffffff;
}

.footer-bottombar {
  background-color: #778aac;
  padding: 25px;
}

.footer-social-icon {
  background-color: #697ea4;
}

.footer-social-icon.face {
  padding: 10px 18px;
  padding-top: 12px;
}

.footer-social-icon.insta {
  padding: 10px 15px;
  padding-top: 12px;
}

.footer-social-icon.envelope {
  padding: 3px 9px;
}

.footer-social-icon.mobile {
  padding: 3px 11px;
}

.footer-social-icon.youtube {
  padding: 10px 12px;
  padding-top: 12px;
}

.footer-tagcoders-logo {
  background-color: #697ea4;
  padding: 16px 0;
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
}
