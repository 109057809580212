.filter-btn {
  background: #ffffff 0% 0% no-repeat padding-box !important;
  /* box-shadow: 0px 20px 40px #2426721c !important; */
  border-radius: 20px !important;
  border: none !important;
  background-color: #8f9db4 !important;
  margin-bottom: 20px;
  text-align: left;
  font-family: Source Sans Pro semibold;
  font-size: 22px !important;
  /* font-weight: 700 !important; */
  letter-spacing: 0px;
  padding: 15px 40px !important;
}

.pink-btn {
  background-color: #2c1419 !important;
  border-radius: 5px !important;
  border: none !important;
  padding: 1rem 1rem !important;
  min-width: 161px !important;
}

.purple-btn {
  background-color: #280f48 !important;
  border-radius: 5px !important;
  border: none !important;
  padding: 1rem 1rem !important;
  min-width: 161px !important;
}

.bg-wrapper {
  background-repeat: no-repeat;
  background-size: contain;
}

@media (min-width: 992px) {
  .bg-wrapper {
    background-size: 65% !important;
  }
}

.bg-wrapper.consultations {
  background-position-x: right;
  background-position-y: top;
}

/* .bg-wrapper.videos {
  background-position: left;
} */

.bg-wrapper.consultations .training-title {
  text-align: right;
}

.bg-wrapper.videos .training-title {
  text-align: left;
}

.pill-yellow {
  color: #e1a32a;
  min-width: 75px;
  background: #ffeece 0% 0% no-repeat padding-box;
  border-radius: 40px;
  padding: 3px 6px;
}

.training-item-title {
  text-align: left;
  font: normal normal bold 18px/25px Source Sans Pro;
  letter-spacing: 0px;
  color: #000000;
}

.training-item-desc {
  text-align: left;
  font-family: 'Source Sans Pro';
  letter-spacing: 0px;
  font-size: 16px;
  color: #909090;
}

.training-title {
  text-align: left;
  font: normal normal bold 44px/63px Source Sans Pro;
  letter-spacing: 0px;
  color: #ffffff;
  margin-top: 100px;
}

.training-details span {
  text-align: left;
  font: normal normal 600 14px/20px Source Sans Pro;
  letter-spacing: 0px;
  color: #000000;
}

.training-price {
  width: auto !important;
  text-align: right;
  font: normal normal 600 14px/20px Source Sans Pro;
  letter-spacing: 0px;
  color: #2c1419;
  display: contents;
}

.training-price.video {
  color: #280f48;
}

.training-slide-arrow {
  position: absolute;
  top: calc(50% - 38px);
  height: 76px;
  display: flex;
  justify-content: flex-start;
  border-radius: 50%;
}

.training-slide-arrow svg {
  align-self: center;
}

.training-item {
  /* min-height: 487px; */
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 2px 2px 8px #0000000d;
  border: 1px solid #f2f2f2;
  border-radius: 10px;
  max-width: 440px;
  margin: 0 auto;
}

.training-slider .slick-slide {
  padding: 0 18px;
}

.training-slide-arrow.prev-arrow {
  left: -2px;
  z-index: 10000;
}

.training-slide-arrow.next-arrow {
  right: -2px;
  z-index: 10000;
}

@media (min-width: 768px) {
  .training-slider .slick-slide {
    padding: 0 !important;
  }

  .training-slide-arrow.prev-arrow {
    left: -20px !important;
  }
  .training-slide-arrow.next-arrow {
    right: -20px !important;
  }
}

/* @media (min-width: 550px) {
  .training-item {
    max-width: 350px !important;
  }

  .training-slide-arrow.prev-arrow {
    left: -15px !important;
  }

  .training-slide-arrow.next-arrow {
    right: -55px !important;
    z-index: 0;
  }
}

@media (min-width: 1439px) {
  .training-item {
    max-width: 400px !important;
  }
} */

.slick-list {
  z-index: 1;
}

.rand-circle {
  position: absolute;
  right: 3%;
  bottom: 75px;
}

.section-dots {
  position: absolute;
  left: -5%;
  top: 65%;
}

/* .training-slider .slick-slide {
  display: flex !important;
  justify-content: center;
}

.training-slider .slick-slide div {
  flex: 1;
} */

.training-slider .slick-track {
  display: flex !important;
}

.training-slider .slick-slide {
  height: inherit !important;
}

.training-slider .slick-slide div:first-of-type:not(.row) {
  height: 100% !important;
  padding: 10px 20px;
}
