.pricing-title {
  color: #040404;
  font-family: 'Source Sans Pro';
  font-size: 40px;
  font-weight: 500;
}

.pricing-subtitle {
  color: #7e92ac;
  font-family: 'Source Sans Pro';
  font-size: 20px;
}

.pricing-vat-info {
  color: #000000;
  font-family: 'Source Sans Pro';
  font-weight: 600;
  font-size: 15px;
}

.pricing-selector {
  border: 1px solid #323a4b;
  border-radius: 18px;
  display: flex;
}

.pricing-inner-selector {
  padding: 13px 20px;
  color: #323a4b;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  min-width: 120px;
  border-radius: 16px;
  cursor: pointer;
  justify-content: center;
}

.pricing-inner-selector.active {
  color: #ffffff;
  background-color: #323a4b;
  cursor: unset;
}

@media (min-width: 400px) {
  .pricing-inner-selector {
    min-width: 181px !important;
  }
}

.pricing-table-row {
  max-width: 350px !important;
  min-height: 506px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 20px 40px #2426721c;
  border-radius: 20px;
  padding: 25px;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
}

.pricing-table-row.best-price {
  border: 0.5px solid #be4e4e;
}

.pricing-row-header {
  text-align: center;
  font-family: 'Source Sans Pro';
  font-weight: 400;
  font-size: 24px;
  letter-spacing: 0px;
  color: #000000;
}

.pricing-row-feature {
  text-align: center;
  font-family: 'Source Sans Pro' !important;
  letter-spacing: 0px;
  padding-top: 1px;
  font-size: 18px;
  color: #778aac;
}

.pricing-row-access {
  text-align: center;
  font-family: 'Source Sans Pro';
  letter-spacing: 0px;
  color: #000000;
}

.pricing-row-benefit {
  text-align: center;
  font-family: 'Source Sans Pro';
  letter-spacing: 0px;
  color: #bcbdbe;
}

.btn-dark-blue {
  background: #323a4b 0% 0% no-repeat padding-box !important;
  border: 2px solid #323a4b !important;
  border-radius: 10px !important;
  color: #fff !important;
  padding: 10px 40px !important;
  text-align: center !important;
  font-family: 'Source Sans Pro';
  font-size: 20px !important;
  letter-spacing: 1.88px !important;
  color: #ffffff !important;
}

.btn-best-price {
  color: #ffffff !important;
  background: #ff5674 0% 0% no-repeat padding-box !important;
  border: 2px solid #ff5674 !important;
}

.price-row-best-price-label {
  text-align: center;
  font-family: 'Source Sans Pro';
  letter-spacing: 0px;
  color: #ffffff;
  background: #ff5674 0% 0% no-repeat padding-box;
  border-radius: 11px;
  padding: 5px 10px;
  position: absolute;
  top: -22px;
  right: calc(50% - 87px);
}

.pricing-row-per-month {
  text-align: center;
  font-family: 'Source Sans Pro';
  font-weight: 700;
  font-size: 30px;
  letter-spacing: 0px;
  color: #303a4d;
}

.pricing-row-price {
  text-align: center;
  font-family: 'Source Sans Pro';
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0px;
  color: #000000;
}

@keyframes skeleton-loading {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}

.skeleton-pricing {
  background-color: #f7f7f7;
  background: linear-gradient(90deg, #e0e0e0 25%, #f0f0f0 50%, #e0e0e0 75%);
  background-size: 200% 100%;
  animation: skeleton-loading 1.5s infinite;
  border-radius: 20px;
  width: 100%;
  height: 450px;
}
