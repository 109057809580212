.heroTitle {
  text-align: center;
  font: normal normal 600 48px/68px Source Sans Pro;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 0.99;
}

.heroSubtitle {
  text-align: center;
  font: normal normal normal 28px/36px Source Sans Pro;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 0.99;
  margin-bottom: 50px;
}

.btnConsult {
  width: 220px;
  z-index: 100;
  height: 70px;
  opacity: 0.9;
  background: #ffffff 0% 0% no-repeat padding-box !important;
  border-color: #ffffff !important;
  font-family: 'Source Sans Pro' !important;
  font-size: 18px !important;
  color: #000000 !important;
  padding: 20px 0 !important;
}

.btnConsultMobile {
  width: 180px;
  height: 67px;
  opacity: 0.9;
  background: #2c1419 0% 0% no-repeat padding-box !important;
  border-color: #ffffff !important;
  box-shadow: 0px 3px 6px #00000033 !important;
  padding: 20px 0 !important;
}

.btnVideo {
  width: 180px;
  height: 67px;
  opacity: 0.9;
  background: #280f48 0% 0% no-repeat padding-box !important;
  border-color: #ffffff !important;
  box-shadow: 0px 3px 6px #00000033 !important;
  padding: 20px 0 !important;
}

.heroVideo {
  position: absolute;
  z-index: 0;
  width: 100%;
  top: 0;
  object-fit: cover;
  height: 100%;
}

.heroWrapper {
  padding-top: 75px;
  margin-bottom: 650px;
}

@media (min-width: 768px) {
  .heroWrapper {
    min-height: 100vh !important;
    margin-bottom: 3rem !important;
  }
}

.container {
  position: relative;
  width: 24px;
  height: 24px;
}

.chevron {
  position: absolute;
  width: 28px;
  height: 8px;
  opacity: 0;
  transform: scale3d(0.5, 0.5, 0.5);
  animation: move 3s ease-out infinite;
}

.chevron:first-child {
  animation: move 3s ease-out 1s infinite;
}

.chevron:nth-child(2) {
  animation: move 3s ease-out 2s infinite;
}

.chevron:before,
.chevron:after {
  content: ' ';
  position: absolute;
  top: 0;
  height: 100%;
  width: 51%;
  background: #fff;
}

.chevron.mobile:before,
.chevron.mobile:after {
  background: #000;
}

.chevron:before {
  left: 0;
  transform: skew(0deg, 30deg);
}

.chevron:after {
  right: 0;
  width: 50%;
  transform: skew(0deg, -30deg);
}

@keyframes move {
  25% {
    opacity: 1;
  }
  33% {
    opacity: 1;
    transform: translateY(30px);
  }
  67% {
    opacity: 1;
    transform: translateY(40px);
  }
  100% {
    opacity: 0;
    transform: translateY(55px) scale3d(0.5, 0.5, 0.5);
  }
}

@keyframes pulse {
  to {
    opacity: 1;
  }
}
