.title {
  color: #040404;
  font-family: 'Source Sans Pro';
  font-size: 40px;
  font-weight: 500;
}

.subtitle {
  text-align: left;
  font: normal normal normal 16px/24px Source Sans Pro;
  letter-spacing: 0px;
  color: #000000;
}

.questionMarkWrapper {
  padding: 15px 20px;
  background: #6ab2bf28 0% 0% no-repeat padding-box;
  box-shadow: 0px 10px 20px #f5e3f91c;
  border-radius: 25px;
  border-color: #7e92ac;
}

.questionTitle {
  margin-top: 10px;
  text-align: left;
  font: normal normal 600 24px/24px Source Sans Pro;
  letter-spacing: 0px;
  color: #000000;
}

.questionBody {
  text-align: left;
  font: normal normal normal 16px/24px Source Sans Pro;
  letter-spacing: 0px;
  color: #7e92ac;
}

.faqCard {
  padding: 15px 25px;
  background-color: #fff;
  box-shadow: 2px 2px 8px #0000000d;
  border: 1px solid #f2f2f2;
  border-radius: 4px;
}

.faqCardTitle {
  margin-top: 30px;
  border-bottom: 0.5px #7e92ac solid;
  font: normal normal normal 24px/30px Source Sans Pro;
  text-align: center;
  color: #7e92ac;
  margin-bottom: 3rem;
  padding-bottom: 1.5rem;
}

.customTab {
  font: normal normal 600 30px/43px Source Sans Pro !important;
  background-color: transparent !important;
  color: #222 !important;
}
