.checkout-title {
  text-align: left;
  font: normal normal bold 44px/63px Source Sans Pro;
  letter-spacing: 0px;
  color: #778aac;
}

.checkout-subtitle {
  text-align: left;
  font: normal normal bold 31px/44px Source Sans Pro;
  letter-spacing: 0px;
  color: #000000;
}

.access-period-label {
  text-align: left;
  font: normal normal normal 14px/20px Source Sans Pro;
  letter-spacing: 0px;
}

.checkout-login-wrapper {
  text-align: left;
  font: normal normal 600 22px/18px Source Sans Pro;
  letter-spacing: 0px;
  color: #ffffff;
  background-color: #b3bed1;
}

.checkout-user-wrapper {
  margin-top: 20px !important;
  box-shadow: 0px 3px 6px #00000029;
}

.checkout_contact_label {
  text-align: left;
  font: normal normal bold 25px/38px Poppins;
  letter-spacing: 0.87px;
  color: #4e3883;
}

.checkout-pay-method-wrapper {
  background-color: #ffffff;
  border: 1px solid #d2d2d2;
}

.checkout-pay-method-wrapper.selected {
  border: 1px solid #3b88fc;
}

.checkout-pay-method-body {
  background-color: #f7f7f7;
}

.center {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.wrapper-checked-invoice {
  padding-bottom: 30px;
}

.wrapper-checkbox-with-info {
  display: flex;
  align-items: center;
  gap: 6px;
}

.tooltip-inner {
  background: #677ca3 !important;
}

.bs-tooltip-top .tooltip-arrow::before {
  border-top-color: #677ca3 !important;
}

.container-error {
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  justify-content: center;
}

.container-error > p {
  font-size: 24px;
  max-width: 600px;
  text-align: center;
}

.container-error > a {
  color: #778aac;
}
