.about-us-title {
  color: #040404;
  font-family: 'Source Sans Pro';
  font-size: 40px;
  font-weight: 500;
}

.about-us-sub-title {
  text-align: left;
  font: normal normal normal 600 25px/38px Source Sans Pro !important;
  letter-spacing: 0px;
  color: #222222;
}

.about-us-info {
  text-align: justify;
  font: normal normal normal 18px/24px Source Sans Pro !important;
  font-weight: 500;
  letter-spacing: 0px;
  color: #222222;
}

.about-us-what-matters {
  text-align: center;
  font: normal normal normal 36px/46px Source Sans Pro !important;
  letter-spacing: 0px;
  color: #222222;
}

.about-us-the-most {
  text-align: center;
  font: normal normal 600 36px/46px Source Sans Pro !important;
  letter-spacing: 0px;
  color: #222222;
}

.about-us-card-title {
  text-align: center;
  font: normal normal normal 24px/24px Source Sans Pro !important;
  letter-spacing: 0px;
  color: #222222;
}

.about-us-card-title strong {
  font: normal normal 600 24px/24px Source Sans Pro;
}
