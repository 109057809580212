.pay-resume-title {
  text-align: left;
  font: normal normal bold 44px/63px Source Sans Pro;
  letter-spacing: 0px;
  color: #778aac;
}

.pay-resume-body {
  text-align: left;
  font: normal normal 300 13px/18px Source Sans Pro;
  letter-spacing: 0px;
  color: #393e46;
}

.pay-resume-bank-title {
  text-align: left;
  font: normal normal bold 38px/54px Source Sans Pro;
  letter-spacing: 0px;
  color: #ff8900;
}

.pay-resume-bank-title-paid {
  text-align: left;
  font: normal normal bold 38px/54px Source Sans Pro;
  letter-spacing: 0px;
  color: green;
}

.pay-resume-bank-subtitle {
  text-align: left;
  font: normal normal medium 13px/18px Roboto;
  letter-spacing: 0px;
  color: #393e46;
}

.pay-resume-label {
  text-align: left;
  font: normal normal 300 13px/18px Roboto;
  letter-spacing: 0px;
  color: #393e46;
  text-transform: uppercase;
}

.pay-resume-account {
  text-align: left;
  font: normal normal medium 20px/26px Roboto;
  letter-spacing: 0px;
  color: #393e46;
}

.pay-resume-info {
  text-align: left;
  font: normal normal bold 16px/18px Source Sans Pro;
  letter-spacing: 0.19px;
  color: #393e46;
}

.text-green {
  color: green !important;
}

.border-10 {
  border-radius: 10px !important;
}

.border-24 {
  border-radius: 24px !important;
}
