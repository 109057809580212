.error-boundary-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  padding-inline: 16px;
  flex-direction: column;
  gap: 16px;
}

.error-boundary-container h1 {
  font-size: 28px;
  max-width: 700px;
  text-align: center;
}

.error-boundary-container .home-link {
  font-size: 18px;
}
