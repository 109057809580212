.overlayWrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-content: center;
  align-items: center;

  justify-content: center;
  position: relative;
  overflow: hidden;
}

.overlayWrapper::after {
  content: '';
  background-color: rgba(0, 0, 0, 0.6);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
}

.image-placeholder {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
}

.icon-play {
  position: absolute;
}

.loader {
  height: 200px;
  position: relative;
  background: #f1f1f1;
  overflow: hidden;
}

.loader::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    90deg,
    rgba(224, 224, 224, 0) 0%,
    rgba(200, 200, 200, 0.6) 50%,
    rgba(224, 224, 224, 0) 100%
  );
  animation: loading 1.6s ease-in-out infinite;
}

@keyframes loading {
  0% {
    transform: translateX(-100%);
  }
  50% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(100%);
  }
}
