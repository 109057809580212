.sub-subtitle {
  text-align: left;
  font: normal normal bold 16px Inter;
  letter-spacing: 0px;
  color: #303a4d;
}

.sub-title {
  text-align: left;
  font: normal normal bold 48px/60px Georgia;
  letter-spacing: 0px;
  color: #002147;
}

.sub-email-placeholder {
  text-align: left;
  font: normal normal normal 16px/60px Inter;
  letter-spacing: 0px;
  color: #bdbdbd;
}

.input-wrapper {
  border: 1px solid #bdbdbd;
  border-radius: 4px;
  padding: 8px;
}

.sub-button {
  background: #697ea4e6 0% 0% no-repeat padding-box !important;
  border-radius: 4px !important;
  border: unset !important;
}

.sub-button:focus {
  box-shadow: unset !important;
}

.sub-input {
  border: unset !important;
}
