.bgWrapper {
  background-repeat: no-repeat;
  padding-top: 75px;
  background-size: 90%;
  background-position-x: 135%;
}

.empty-results {
  color: #280f48;
  font-family: 'Source Sans Pro';
  font-size: 30px;
  font-weight: 500;
}

@media (max-width: 767px) {
  .bgWrapper {
    background-size: 800px;
    background-position-x: 90%;
  }
}
